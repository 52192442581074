















































































































































import { Component, Model, Prop, Vue } from 'vue-property-decorator';
import label from '@/resources/labels/electricity-contract-box.json';
import utils from '@/common/utils';

@Component
export default class ElectricityContractBox extends Vue {
  readonly label: any = label;
  readonly HYPHEN: string = '-';
  readonly utils = utils;

  @Prop({ required: true })
  contract!: any;

  @Prop({ required: true })
  invoice!: any;

  @Prop({ required: true })
  invoices!: any;

  @Model('updatePageId', { type: String })
  readonly activePageId!: string;

  /**
   * 契約ステータスが契約中か判定する
   */
  get isOnGoing() {
    const cd = this.contract.contract_status_type;
    return this.$cls.DENGASU_CONTRACT_STATUS_CLS.ONGOING.CD === cd;
  }

  /**
   * 選択された年月の請求情報を持たない場合はtrueを返す
   */
  get notHaveInvoice() {
    return 0 === Object.keys(this.invoice).length;
  }

  /**
   * 契約に紐づく請求情報が0件の場合はtrueを返す
   */
  get notHaveInvoices() {
    return 0 === this.invoices.length;
  }

  /**
   * 指定されたページIDに応じたボタンクラスを返す
   */
  btnClass(cls: string) {
    const pageId = this.$cls.ENERGY_PAGE_CONTENTS_CLS[cls].CD;
    if (this.wrapperActivePageId === pageId) {
      return 'u-btn01 u-btn01-arrowR u-btn01-current';
    }
    return 'u-btn01 u-btn01-arrowR';
  }

  /**
   * 指定されたページIDへ画面遷移を実施する。また、指定された契約IDを親コンポーネントへ通知する
   */
  switchPageAndSendContractId(cls: string) {
    const pageId = this.$cls.ENERGY_PAGE_CONTENTS_CLS[cls].CD;
    this.$emit('updatePageId', pageId); // 親P0006へイベント通知
    this.$emit('updateContractId', this.contract.id); // 契約IDを親へ通知
  }

  /**
   * 画面遷移用
   */
  get wrapperActivePageId() {
    return this.activePageId;
  }

  /**
   * 画面遷移用
   */
  set wrapperActivePageId(value: string) {
    this.$emit('updatePageId', value);
  }
}
