


























































































































import { Component, Model, Prop, Vue } from 'vue-property-decorator';

@Component
export default class GasContractModal extends Vue {
  @Model('closeDialog', { type: Boolean })
  readonly dialog!: boolean;

  @Prop({ required: true })
  readonly contract!: any;
}
