



































































































































































































































































import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator';
import * as api from '@/gen/api';
import GasContractBox from '@/components/energy/GasContractBox.vue';
import label from '@/resources/labels/gas-invoice-detail.json';
import $ from 'jquery';
import { GAS_DISCOUNT_AMOUNT_JPY } from '@/common/constants';

@Component({
  components: {
    GasContractBox
  }
})
export default class GasInvoiceDetails extends Vue {
  readonly label: any = label;

  readonly HYPHEN: string = '-';
  readonly genryoNo = '102000';

  @Prop({ required: true })
  readonly contract!: any;

  @Prop({ required: true })
  readonly invoice!: any;

  @Prop({ required: true })
  readonly invoices!: any;

  @Prop({ required: true })
  readonly selectedYm!: string;

  @Model('updatePageId', { type: String })
  readonly activePageId!: string;

  details: api.ResGasInvoiceDetail[] = [];

  /**
   * 請求明細をサーバから取得
   */
  @Watch('invoice', { immediate: true })
  async watchContractHandler() {
    const config = await this.$getConfigWithToken(this.$auth);
    const client = new api.GasApi(config);

    const invoiceId: number = this.invoice.id;
    const res = await client
      .getGasInvoiceDetails(invoiceId)
      .catch(this.$tokenErrHandler);

    this.details = res.data;
  }

  /**
   * MR: https://ode.futurefraqta.io/gitlab/platform/sources/web-app/-/merge_requests/1726
   * ガスの激変緩和措置の対象年月の明細を参照した場合、値引き額を返却する
   * selectedYm: YYYYMM
   */
  get gasDiscountAmount() {
    if (this.selectedYm in GAS_DISCOUNT_AMOUNT_JPY) {
      return GAS_DISCOUNT_AMOUNT_JPY[this.selectedYm];
    }
    return 0;
  }

  /**
   * 明細行に表示する明細情報
   * 2020.04.12 miyazaki
   * https://nasa.future.co.jp/ode/redmine/issues/755
   * 原料費調整額も明細行として表示する
   */
  get mainDetails() {
    return this.details;
  }

  /**
   * 原料費調整額の明細
   */
  get genryoDetails() {
    return this.details.filter((i: any) => i.detail_cd === this.genryoNo);
  }

  /**
   * 印刷をクリックした時の振る舞い。
   */
  openPrinter() {
    const printable = 'printingException';

    // bodyに印刷用クラスを付与
    $('body').addClass(printable);

    // 印刷プレビューを呼び出す
    window.print();

    // bodyから印刷用クラスを削除
    window.addEventListener('afterprint', () => {
      $('body').removeClass(printable);
    });
  }

  /**
   * 契約ステータス
   */
  get contractStatusLabel() {
    const cd = this.contract.contract_status_type;
    return this.$cls.getName('DENGASU_CONTRACT_STATUS_CLS', cd);
  }

  /**
   * ページ遷移用
   */
  get wrapperActivePageId() {
    return this.activePageId;
  }

  /**
   * ページ遷移用
   */
  set wrapperActivePageId(value: string) {
    this.$emit('updatePageId', value);
  }
}
