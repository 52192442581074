

































































































































import { Component, Model, Prop, Vue } from 'vue-property-decorator';
import label from '@/resources/labels/electricity-contract-view-label.json';
import ElectricityContractBox from '@/components/energy/ElectricityContractBox.vue';
import utils from '@/common/utils';

@Component({
  components: {
    ElectricityContractBox
  }
})
export default class ElectricityContract extends Vue {
  readonly label: any = label;
  readonly utils = utils;

  @Prop({ required: true })
  readonly contract!: any;

  @Prop({ required: true })
  readonly invoice!: any;

  @Prop({ required: true })
  readonly invoices!: any;

  @Prop({ required: true })
  readonly selectedYm!: string;

  @Model('updatePageId', { type: String })
  readonly activePageId!: string;

  /**
   * 契約ステータス
   */
  get contractStatus() {
    const cd = this.contract.contract_status_type;
    return this.$cls.getName('DENGASU_CONTRACT_STATUS_CLS', cd);
  }

  /**
   * ページ遷移用
   */
  get wrapperActivePageId() {
    return this.activePageId;
  }

  /**
   * ページ遷移用
   */
  set wrapperActivePageId(value: string) {
    this.$emit('updatePageId', value);
  }
}
