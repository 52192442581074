












































































































import { Component, Model, Prop, Vue } from 'vue-property-decorator';
import dayjs from 'dayjs';
import ElectricityContractBox from '@/components/energy/ElectricityContractBox.vue';
import chartUtils from '@/common/energyChartUtils';

@Component({
  components: {
    ElectricityContractBox
  }
})
export default class ElectricityChart extends Vue {
  readonly USED_AMOUNT_UNIT = 'kWh';

  @Prop({ required: true })
  readonly contract!: any;

  @Prop({ required: true })
  readonly invoice!: any;

  @Prop({ required: true })
  readonly invoices!: any;

  @Prop({ required: true })
  readonly selectedYm!: string;

  @Model('updatePageId', { type: String })
  readonly activePageId!: string;

  thisPaidList: Array<number | null> = [];
  lastPaidList: Array<number | null> = [];
  thisUsedList: Array<number | null> = [];
  lastUsedList: Array<number | null> = [];

  myChart: any;

  created() {
    this.initProperties();
  }

  /**
   * グラフ描画用のデータを作成する
   */
  initProperties() {
    // 請求情報が埋められた24カ月分の配列を作成する
    const filledInvoices = chartUtils.createFilledInvoices(
      this.invoices,
      this.getToday()
    );

    // 金額のみ、使用量のみのリストを抽出する
    const paidList = filledInvoices.map(
      (i: any) => i.this_m_tax_included_invoice_amount
    );
    const usedList = filledInvoices.map((i: any) => i.this_m_used_amount);

    // 今年と前年で分割して、プロパティをアップデートする
    this.lastPaidList = paidList.slice(0, 12);
    this.thisPaidList = paidList.slice(12, 24);
    this.lastUsedList = usedList.slice(0, 12);
    this.thisUsedList = usedList.slice(12, 24);
  }

  mounted() {
    this.initChart();
  }

  /**
   * グラフ描画時に毎回Chartインスタンスを作成しているため、
   * 画面切り替え時にインスタンスを破棄してメモリリークを防ぐ
   */
  beforeDestroy() {
    if (this.myChart) {
      this.myChart.destroy;
    }
  }

  /**
   * グラフの描画を行う
   */
  initChart() {
    // グラフ描画のためデータセットへ変換

    // 前年の利用額
    const lastPaidDataset = chartUtils.createLineDataset(
      this.lastPaidList,
      chartUtils.lastPaidOptions
    );

    // 今年の利用額
    const thisPaidDataset = chartUtils.createLineDataset(
      this.thisPaidList,
      chartUtils.thisPaidOptionsElectricity
    );

    // 前年の使用量
    const lastUsedDataset = chartUtils.createBarDataset(
      this.lastUsedList,
      chartUtils.lastUsedOptions,
      this.USED_AMOUNT_UNIT
    );

    // 今年の使用量
    const thisUsedDataset = chartUtils.createBarDataset(
      this.thisUsedList,
      chartUtils.thisUsedOptions,
      this.USED_AMOUNT_UNIT
    );

    // データセットからグラフを描画する
    this.myChart = chartUtils.createChart(
      {
        labels: this.xAxes, // x軸
        // データセットは配列の先頭の方が手前に描画される
        datasets: [
          lastPaidDataset,
          thisPaidDataset,
          lastUsedDataset,
          thisUsedDataset
        ]
      },
      this.USED_AMOUNT_UNIT
    );

    if (!this.myChart) {
      // グラフのレンダリングが失敗
      return;
    }

    // グラフのデザイン調整を行う
    chartUtils.adjustChart(this.myChart);

    // 微調整後の更新
    this.myChart.update();
  }

  /**
   * 12カ月分の"XX月"の配列を返す。末尾は必ず実行日時の月。
   * 例：1月, 2月, ..., 12月
   */
  get xAxes() {
    return chartUtils.createXAxes(this.getToday().format('M'));
  }

  /**
   * TODO(k.sakiyama.d7@future.co.jp) タイムゾーンの指定が必要か
   */
  getToday() {
    return dayjs();
  }

  /**
   * 契約ステータス
   */
  get contractStatus() {
    const cd = this.contract.contract_status_type;
    return this.$cls.getName('DENGASU_CONTRACT_STATUS_CLS', cd);
  }

  /**
   * ページ遷移用
   */
  get wrapperActivePageId() {
    return this.activePageId;
  }

  /**
   * ページ遷移用
   */
  set wrapperActivePageId(value: string) {
    this.$emit('updatePageId', value);
  }
}
