import { Chart } from 'chart.js';
import dayjs from 'dayjs';
import numeral from 'numeral';
import utils from '@/common/utils';
import $ from 'jquery';

export default class EnergyChartUtils {
  public static readonly lastPaidOptions = {
    color: {
      background: { r: 75, g: 87, b: 93, a: 1.0 },
      border: { r: 75, g: 87, b: 93, a: 1.0 }
    },
    label: '前年の利用額',
    borderWidth: 2
  };

  public static readonly thisPaidOptionsElectricity = {
    color: {
      background: { r: 255, g: 194, b: 13, a: 1.0 },
      border: { r: 255, g: 194, b: 13, a: 1.0 }
    },
    label: '今年の利用額',
    borderWidth: 2
  };

  public static readonly thisPaidOptionsGas = {
    color: {
      background: { r: 242, g: 96, b: 40, a: 1.0 },
      border: { r: 242, g: 96, b: 40, a: 1.0 }
    },
    label: '今年の利用額',
    borderWidth: 2
  };

  public static readonly lastUsedOptions = {
    color: {
      background: { r: 221, g: 221, b: 221, a: 1.0 },
      border: { r: 221, g: 221, b: 221, a: 1.0 }
    },
    label: '前年の使用量'
  };

  public static readonly thisUsedOptions = {
    color: {
      background: { r: 2, g: 131, b: 205, a: 1.0 },
      border: { r: 2, g: 131, b: 205, a: 1.0 }
    },
    label: '今年の使用量'
  };

  /**
   * 当月から始まる24カ月分の配列を作成する
   * また、請求が存在する年月の場合、請求情報を埋める
   */
  public static createFilledInvoices(invoices: any, startDate: dayjs.Dayjs) {
    const empty = {
      this_m_tax_included_invoice_amount: null,
      this_m_used_amount: null
    };
    return [...Array(24).keys()]
      .reverse()
      .map(i => startDate.subtract(i, 'month').format('YYYYMM'))
      .map(e => invoices.find((i: any) => i.target_ym === e) || empty);
  }

  /**
   * グラフのデザイン調整を実施する。
   * width, height, フォントサイズ
   * 型はChartを使えるが、nullable考慮が難しいのでanyを設定
   */
  public static adjustChart(chart: any) {
    const refWidth = 762;
    const defaultFontSize = 14;
    // TODO(k.sakiyama.d7@future.co.jp)
    // const defaultBorderWidth = 2;
    // const defaultBoxWidth = 40;

    const minHeight = 320;
    const minFontSize = 7;
    const windowWidth = $(window).width() || 0;

    if (windowWidth >= 768) {
      // PCビュー

      // フォントサイズ
      const size = (defaultFontSize / refWidth) * chart.width;
      chart.config.options.scales.yAxes[0].ticks.fontSize = size;
      chart.config.options.scales.yAxes[1].ticks.fontSize = size;
      chart.config.options.scales.xAxes[0].ticks.fontSize = size;
      chart.config.options.legend.labels.fontSize = size;

      // heightはchart.jsが自動調整
      chart.config.options.maintainAspectRatio = true;

      // minHeightより小さいheightにはならない
      const height = Math.max(chart.height, minHeight);
      chart.canvas.style.height = `${height}px`;
      chart.height = height;
    } else {
      // SPビュー

      // フォントサイズ
      chart.config.options.scales.yAxes[0].ticks.fontSize = minFontSize;
      chart.config.options.scales.yAxes[1].ticks.fontSize = minFontSize;
      chart.config.options.scales.xAxes[0].ticks.fontSize = minFontSize;
      chart.config.options.legend.labels.fontSize = minFontSize;

      // heightの自動調整は無し
      chart.config.options.maintainAspectRatio = false;

      // heightはminHeightで固定
      chart.canvas.style.height = `${minHeight}px`;
      chart.height = minHeight;
    }

    // TODO(k.sakiyama.d7@future.co.jp) 判例のBOXを設定するコード。デザイン調整の時に有効にする可能性があるため、コメントアウト
    // chart.config.options.legend.labels.boxWidth =
    //   (defaultBoxWidth / refWidth) * chart.width;

    // TODO(k.sakiyama.d7@future.co.jp) 使用量のボーダー横幅を設定するコード。IWになぜここだけ設定しているか確認が必要
    // chart.config.data.datasets[1].borderWidth =
    //   (defaultBorderWidth / refWidth) * chart.width;
  }

  /**
   * rgbaの文字列に変換して返す
   */
  private static createRgbaString(rgba: any) {
    return `rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, ${rgba.a})`;
  }

  /**
   * 折れ線グラフのデータセットを構築
   */
  public static createLineDataset(values: Array<number | null>, options: any) {
    const backgroundColor = new Array<string>(12).fill(
      this.createRgbaString(options.color.background)
    );
    const borderColor = new Array<string>(12).fill(
      this.createRgbaString(options.color.border)
    );

    return {
      type: 'line',
      label: options.label,
      data: values,
      backgroundColor,
      borderColor,
      borderWidth: options.borderWidth,
      fill: false,
      lineTension: 0,
      yAxisID: '円'
    };
  }

  /**
   * 棒グラフのデータセットを構築
   */
  public static createBarDataset(
    values: Array<number | null>,
    options: any,
    unit: string
  ) {
    const backgroundColor = new Array<string>(12).fill(
      this.createRgbaString(options.color.background)
    );
    const borderColor = new Array<string>(12).fill(
      this.createRgbaString(options.color.border)
    );

    return {
      label: options.label,
      data: values,
      backgroundColor,
      borderColor,
      borderWidth: options.borderWidth,
      yAxisID: unit
    };
  }

  /**
   * グラフ描画
   */
  public static createChart(data: any, usedAmountUnit: string) {
    const element = document.getElementById('chart') as any;
    if (!element) {
      return;
    }

    const myChart = new Chart(element.getContext('2d'), {
      type: 'bar',
      data,
      options: {
        // responsive: true, // responsiveはONにしない
        legend: {
          // 凡例の設定
          labels: {}
        },
        scales: {
          xAxes: [
            {
              ticks: {
                autoSkip: true, // X軸の並びが窮屈な場合に、自動でスキップ表示する
                maxRotation: 0,
                minRotation: 0
              }
            }
          ],
          yAxes: [
            {
              // 使用量の設定
              type: 'linear',
              id: usedAmountUnit,
              ticks: {
                callback: (label: string) =>
                  this.formatYAxesLabel(label, usedAmountUnit)
              },
              position: 'left' // 使用量は左Y軸
            },
            {
              // 利用額の設定
              type: 'linear',
              id: '円',
              gridLines: {
                display: false // 使用量で横線の補助線を引くため、利用額は非表示
              },
              ticks: {
                beginAtZero: true, // 利用額は0円から表示
                callback: (label: string) => this.formatYAxesLabel(label, '円')
              },
              position: 'right' // 利用額は右Y軸
            }
          ]
        },
        onResize: (chart: any) => {
          this.adjustChart(chart);
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              // グラフをマウスオーバー時に表示される吹き出し上で単位をつける
              const dataset =
                (data.datasets &&
                  tooltipItem.datasetIndex !== undefined &&
                  data.datasets[tooltipItem.datasetIndex]) ||
                {};
              // データセットのyAxisIDを単位とする。 yenの場合は円に直す。
              const yAxisID = dataset.yAxisID || '';
              return this.formatYAxesLabel(tooltipItem.yLabel, yAxisID);
            }
          }
        }
      }
    });
    // canvasのサイズで初期化
    myChart.width = element.style.width;
    myChart.height = element.style.height;
    return myChart;
  }

  /**
   * X軸の12カ月を作成する
   */
  public static createXAxes(targetM: string) {
    const t = parseInt(targetM);
    let m = utils.createNumberArray(12).map((i: string) => i + '月');

    for (let i = 0; i < t; i++) {
      const top = m.shift();
      m.push(top!);
    }

    return m;
  }

  /**
   * Y軸ラベルを数値フォーマットし、単位をつける
   */
  private static formatYAxesLabel(label: any, unit: string) {
    return numeral(label).format('0,0') + unit;
  }
}
