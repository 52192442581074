














































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import dayjs from 'dayjs';
import EnergyUnregistered from '@/components/energy/EnergyUnregistered.vue';
import ContractList from '@/components/energy/ContractList.vue';
import ElectricityContractView from '@/components/energy/ElectricityContractView.vue';
import ElectricityContractModal from '@/components/energy/ElectricityContractModal.vue';
import ElectricityInvoiceDetail from '@/components/energy/ElectricityInvoiceDetail.vue';
import ElectricityChart from '@/components/energy/ElectricityChart.vue';
import GasContractView from '@/components/energy/GasContractView.vue';
import GasContractModal from '@/components/energy/GasContractModal.vue';
import GasInvoiceDetail from '@/components/energy/GasInvoiceDetail.vue';
import GasChart from '@/components/energy/GasChart.vue';
import label from '@/resources/labels/energy-top.json';
import { EnergyRepository } from '@/repositories/EnergyRepository';
import EnegryContact from '@/components/energy/EnegryContact.vue';
import NewsScroll from '@/components/NewsScroll.vue';

@Component({
  components: {
    EnergyUnregistered,
    ContractList,
    ElectricityContractView,
    ElectricityContractModal,
    ElectricityInvoiceDetail,
    ElectricityChart,
    GasContractView,
    GasContractModal,
    GasInvoiceDetail,
    GasChart,
    EnegryContact,
    NewsScroll
  }
})
export default class P0019 extends Vue {
  readonly label: any = label;
  statusNotice = [false, false];

  activePageId: string = '';

  // サーバから取得した値を保持
  electricityContractInvoices: any[] = [];
  gasContractInvoices: any[] = [];

  ymList: any[] = [{ text: '', value: '' }];

  // 選択中のパラメータ
  selectedYm: string = ''; // 月別ページングヘッダがアップデートする
  selectedContractId: string = ''; // 子コンポーネントのContractBoxのclickイベントがアップデートする

  electricityDialog: boolean = false;
  gasDialog: boolean = false;

  /**
   * 契約が0件の場合、未加入であると判定する
   */
  get isUnregistered() {
    return (
      this.electricityContractInvoices.length === 0 &&
      this.gasContractInvoices.length === 0
    );
  }

  /**
   * 電気の契約数を踏まえて、加入済か判定する
   */
  get isElectricityRegistered() {
    return this.electricityContractInvoices.length !== 0;
  }

  /**
   * ガスの契約数を踏まえて、加入済か判定する
   */
  get isGasRegistered() {
    return this.gasContractInvoices.length !== 0;
  }

  /**
   * 選択された契約ID の電気契約を返す。対象がない場合は空の配列を返す
   */
  get selectedElectricityContract() {
    const l = this.electricityContractInvoices
      .filter((i: any) => i.contract.id === this.selectedContractId)
      .map((i: any) => i.contract);
    return l.length !== 0 ? l[0] : [];
  }

  /**
   * 選択された契約ID のガス契約を返す。対象がない場合は空の配列を返す
   */
  get selectedGasContract() {
    const l = this.gasContractInvoices
      .filter((i: any) => i.contract.id === this.selectedContractId)
      .map((i: any) => i.contract);
    return l.length !== 0 ? l[0] : [];
  }

  /**
   * 詳細用に 選択された契約ID かつ 選択された月の請求を返す。対象がない場合は空のオブジェクトを返す
   */
  get electricityInvoiceDetail() {
    const l = this.electricityContractInvoices
      .filter((i: any) => i.contract.id === this.selectedContractId)
      .flatMap((i: any) => i.invoices)
      .filter((i: any) => i.target_ym === this.selectedYm);
    return l.length !== 0 ? l[0] : {};
  }

  /**
   * 詳細用に 選択された契約ID かつ 選択された月の請求を返す。対象がない場合は空のオブジェクトを返す
   */
  get gasInvoiceDetail() {
    const l = this.gasContractInvoices
      .filter((i: any) => i.contract.id === this.selectedContractId)
      .flatMap((i: any) => i.invoices)
      .filter((i: any) => i.target_ym === this.selectedYm);
    return l.length !== 0 ? l[0] : {};
  }

  /**
   * 選択された契約ID の請求リストを返す（電気）
   */
  get electricityInvoices() {
    return this.electricityContractInvoices
      .filter((i: any) => i.contract.id === this.selectedContractId)
      .flatMap((i: any) => i.invoices);
  }

  /**
   * 選択された契約ID の請求リストを返す（ガス）
   */
  get gasInvoices() {
    return this.gasContractInvoices
      .filter((i: any) => i.contract.id === this.selectedContractId)
      .flatMap((i: any) => i.invoices);
  }

  getTodayDayjs() {
    return dayjs();
  }

  getTodayYyyyMm() {
    return this.getTodayDayjs().format('YYYYMM');
  }

  /**
   * テストのため分離
   */
  get repository() {
    return new EnergyRepository();
  }

  @Watch('$auth.loading', { immediate: true })
  async initialize() {
    if (!this.$auth.isAuthenticated) {
      return;
    }

    this.switchPage(this.$cls.ENERGY_PAGE_CONTENTS_CLS.TOP.CD);

    const {
      electricityInvoices,
      gasInvoices
    } = await this.repository.fetchEnergyInvoices();
    this.electricityContractInvoices = electricityInvoices;
    this.gasContractInvoices = gasInvoices;

    // 未加入者の場合、処理終了
    if (this.isUnregistered) {
      return;
    }

    // 年月関連のデータを初期化
    this.initializeYmValues();
  }

  initializeYmValues() {
    // 年月選択肢
    this.ymList = this.createDefaultYmList();
    // 選択された年月初期値をリスト内最新に設定
    this.selectedYm = this.ymList[0].value;
  }

  createDefaultYmList() {
    const invoiceYmList = this.createYmList(
      this.distinct(
        [
          this.extractYmList(this.electricityContractInvoices),
          this.extractYmList(this.gasContractInvoices)
        ],
        false
      )
    );
    // 年月リストが空の場合は歴月を追加する。
    // ※未請求契約のみの場合
    if (!invoiceYmList.length) {
      return this.createYmList([this.getTodayYyyyMm()]);
    }
    return invoiceYmList;
  }

  /**
   * 対象から請求実績のある年月を抽出
   */
  extractYmList(target: any): string[] {
    return target.flatMap((e: any) => e.invoices).map((i: any) => i.target_ym);
  }

  /**
   * 値の重複を削除したリストを返す
   */
  distinct(lists: any[], asc: boolean = true): string[] {
    const list = [...new Set(lists.flatMap(i => i))];
    return list.sort((a, b) => ((asc ? a > b : a < b) ? 1 : -1));
  }

  /**
   * 年月プルダウンの選択肢を生成
   */
  createYmList(list: string[]): any[] {
    const formatStr = 'YYYY年 M月分';
    return list.map((c: any) => ({
      text: dayjs(c).format(formatStr),
      value: c
    }));
  }

  showPrevMonth() {
    this.selectedYm = this.ymList[this.selectedYmIndex + 1].value;
  }

  showNextMonth() {
    this.selectedYm = this.ymList[this.selectedYmIndex - 1].value;
  }

  get selectedYmIndex() {
    return this.ymList.findIndex(e => e.value === this.selectedYm);
  }

  get isDisablePrevMonthBtn() {
    return this.selectedYm === this.ymList[this.ymList.length - 1].value;
  }

  get isDisableNextMonthBtn() {
    return this.selectedYm === this.ymList[0].value;
  }

  isActivePage(cls: string) {
    const pageId = this.$cls.ENERGY_PAGE_CONTENTS_CLS[cls].CD;
    return pageId === this.activePageId;
  }

  /**
   * ページ遷移用
   */
  switchPage(pageId: string) {
    this.activePageId = pageId;
  }

  /**
   * 指定したページIDヘ遷移し、スクロールを元に戻す
   */
  switchPageScrollTop(pageId: string) {
    this.switchPage(pageId);
    document.documentElement.scrollTop = 0;
  }

  @Watch('activePageId')
  updateYmList() {
    this.ymList = this.createYmListByActivePage();
  }

  createYmListByActivePage() {
    // 電気利用明細の場合
    if (this.isActivePage('ELECTRICITY_INVOICE')) {
      const list = this.electricityInvoices.map((i: any) => i.target_ym);
      return this.createYmList(this.distinct(list, false));
    }
    // ガス利用明細の場合
    if (this.isActivePage('GAS_INVOICE')) {
      const list = this.gasInvoices.map((i: any) => i.target_ym);
      return this.createYmList(this.distinct(list, false));
    }
    return this.createDefaultYmList();
  }

  onClickNoticeOpenToggle(index: number) {
    this.$set(this.statusNotice, index, !this.statusNotice[index]);
  }
}
