
























































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import EnegryContact from '@/components/energy/EnegryContact.vue';

@Component({
  components: {
    EnegryContact
  }
})
export default class EnergyUnregistered extends Vue {
  submitSimulationForm() {
    var formElem = document.getElementById('simulationForm') as HTMLFormElement;
    formElem.submit();
  }
}
