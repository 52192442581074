























































































































































































































import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator';
import * as api from '@/gen/api';
import label from '@/resources/labels/p0022label.json';
import ElectricityContractBox from '@/components/energy/ElectricityContractBox.vue';
import $ from 'jquery';

@Component({
  components: {
    ElectricityContractBox
  }
})
export default class ElectricityDetail extends Vue {
  readonly label: any = label;

  readonly HYPHEN: string = '-';

  @Prop({ required: true })
  readonly contract!: any;

  @Prop({ required: true })
  readonly invoice!: any;

  @Prop({ required: true })
  readonly invoices!: any;

  @Prop({ required: true })
  readonly selectedYm!: string;

  @Model('updatePageId', { type: String })
  readonly activePageId!: string;

  // テーブルのカラム名
  readonly headers: Array<{ text: string; align?: string; value: string }> = [
    { text: label.detailItemName, value: 'item_name' },
    { text: label.detailQty, value: 'qty' },
    {
      text: label.detailTaxIncludedUnitPrice,
      value: 'tax_included_unit_price'
    },
    { text: label.detailTaxIncludedAmount, value: 'tax_included_amount' },
    { text: label.detailConTaxAmount, value: 'con_tax_amount' }
  ];

  details: Array<api.ResElectricityInvoiceDetail> = [];

  /**
   * 請求明細をサーバから取得
   */
  @Watch('invoice', { immediate: true })
  async fetchElectricityInvoiceDetails() {
    const config = await this.$getConfigWithToken(this.$auth);
    const electricityApi = new api.ElectricityApi(config);
    const ret = await electricityApi
      .getElectricityInvoiceDetails(this.invoice.id)
      .catch(this.$tokenErrHandler);

    this.details = ret.data;
  }

  /**
   * 印刷をクリックした時の振る舞い。
   */
  openPrinter() {
    const printable = 'printingException';

    // bodyに印刷用クラスを付与
    $('body').addClass(printable);

    // 印刷プレビューを呼び出す
    window.print();

    // bodyから印刷用クラスを削除
    window.addEventListener('afterprint', () => {
      $('body').removeClass(printable);
    });
  }

  /**
   * 契約ステータス
   */
  get contractStatusLabel() {
    const cd = this.contract.contract_status_type;
    return this.$cls.getName('DENGASU_CONTRACT_STATUS_CLS', cd);
  }

  /**
   * ページ遷移用
   */
  get wrapperActivePageId() {
    return this.activePageId;
  }

  /**
   * ページ遷移用
   */
  set wrapperActivePageId(value: string) {
    this.$emit('updatePageId', value);
  }
}
