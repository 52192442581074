























































import { Component, Vue, Model, Prop } from 'vue-property-decorator';
import ElectricityContractBox from './ElectricityContractBox.vue';
import GasContractBox from './GasContractBox.vue';

@Component({
  components: {
    ElectricityContractBox,
    GasContractBox
  }
})
export default class ContractList extends Vue {
  @Prop({ required: true })
  electricityContractInvoices!: any[];

  @Prop({ required: true })
  gasContractInvoices!: any[];

  @Prop({ required: true })
  selectedYm!: string;

  @Model('updatePageId', { type: String })
  readonly activePageId!: string;

  // 選択された月の請求を返す。存在しない場合は空のオブジェクトを返す
  filterSelectedYm(invoices: any[]) {
    const ret = invoices.filter((i: any) => i.target_ym === this.selectedYm);
    return ret.length ? ret[0] : {};
  }

  // 契約中の電気契約のみ抽出する。この条件はプルダウンの選択月に影響しない
  get ongoingElectricityContractInvoices() {
    const cd = this.$cls.DENGASU_CONTRACT_STATUS_CLS.ONGOING.CD;
    return this.electricityContractInvoices.filter(
      (i: any) => i.contract.contract_status_type === cd
    );
  }

  // 契約中のガス契約のみ抽出する。この条件はプルダウンの選択月に影響しない
  get ongoingGasContractInvoices() {
    const cd = this.$cls.DENGASU_CONTRACT_STATUS_CLS.ONGOING.CD;
    return this.gasContractInvoices.filter(
      (i: any) => i.contract.contract_status_type === cd
    );
  }

  // 契約終了の電気契約のみ抽出する。この条件はプルダウンの選択月に影響しない
  get expiredElectricityContractInvoices() {
    const cd = this.$cls.DENGASU_CONTRACT_STATUS_CLS.END.CD;
    return this.electricityContractInvoices.filter(
      (i: any) => i.contract.contract_status_type === cd
    );
  }

  // 契約終了のガス契約のみ抽出する。この条件はプルダウンの選択月に影響しない
  get expiredGasContractInvoices() {
    const cd = this.$cls.DENGASU_CONTRACT_STATUS_CLS.END.CD;
    return this.gasContractInvoices.filter(
      (i: any) => i.contract.contract_status_type === cd
    );
  }

  get showOngoingLabel() {
    return (
      !!this.ongoingElectricityContractInvoices.length ||
      !!this.ongoingGasContractInvoices.length
    );
  }

  get showExpiredLabel() {
    return (
      !!this.expiredElectricityContractInvoices.length ||
      !!this.expiredGasContractInvoices.length
    );
  }

  /**
   * 契約IDを親へ通知
   */
  updateContractId(contractId: string) {
    this.$emit('updateContractId', contractId);
  }

  /**
   * ページ遷移用
   */
  switchPage(pageId: string) {
    this.$emit('updatePageId', pageId); // 親P0006へイベント通知
  }

  /**
   * ページ遷移用
   */
  get wrapperActivePageId() {
    return this.activePageId;
  }

  /**
   * ページ遷移用
   */
  set wrapperActivePageId(value: string) {
    this.$emit('updatePageId', value);
  }
}
